import { FC, PropsWithChildren } from "react";

import { classNames } from "@frec-js/common-web";

export const BubbleText: FC<
  PropsWithChildren<{ className?: string; dark?: boolean }>
> = ({ children, className, dark }) => {
  return (
    <span
      className={classNames(
        "inline-block rounded-full border-2 px-4 pt-0 pb-1 leading-tight",
        dark ? "text-frecNeon bg-frecBlack" : "bg-frecNeon border-[#242424]",
        className,
      )}
    >
      {children}
    </span>
  );
};
