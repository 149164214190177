import { FC, PropsWithChildren, useEffect, useRef } from "react";

import { classNames } from "@frec-js/common-web";
import { useColorSchemeContext } from "../providers/ColorSchemeProvider";

const thresholds: number[] = [];
for (let i = 0; i <= 1.0; i += 0.01) {
  thresholds.push(i);
}

// This component is used to toggle the header to dark when the section is in view.
// If two dark sections are on the screen at the same time, it can cause the header to flicker,
// because one will set it light while the other sets it dark.
export const DarkSection: FC<
  PropsWithChildren<{ id?: string; className?: string }>
> = ({ id, className, children }) => {
  const { headerTheme, setHeaderTheme } = useColorSchemeContext();

  // use a ref on the section
  const sectionRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!sectionRef.current) return;
    // use an intersection observer to check if the section is in view and then toggle the mantine colorscheme to dark
    // tailwind follows mantine's color scheme
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (
            entries[0].intersectionRect.top < 76 &&
            entries[0].intersectionRect.bottom > 76
          ) {
            setHeaderTheme("dark");
          } else {
            setHeaderTheme("light");
          }
        }
      },
      { threshold: thresholds },
    );
    observer.observe(sectionRef.current);
    return () => {
      if (headerTheme !== "light") setHeaderTheme("light");
      observer.disconnect();
    };
  }, [headerTheme, setHeaderTheme]);

  return (
    <section
      id={id}
      ref={sectionRef}
      className={classNames("bg-frecBlack dark w-full", className)}
    >
      {children}
    </section>
  );
};
